import React from 'react';

const Footer = () => {
    return <footer className="footer d-print-none">
      <div className="container-xl">
        <div className="row text-center align-items-center flex-row-reverse">
          <div className="col-lg-auto ms-lg-auto">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item"><a className="link-secondary" target="_blank" rel="noreferrer" href="https://docs.commissionsportal.com/en/terms-of-service">Terms</a></li>
              <li className="list-inline-item"><a className="link-secondary" target="_blank" rel="noreferrer" href="https://docs.commissionsportal.com/en/privacy-statement">Privacy</a></li>
              <li className="list-inline-item"><a className="link-secondary" target="_blank" rel="noreferrer" href="https://pillars-hub.readme.io/docs/introduction">Help</a></li>
            </ul>
          </div>
          <div className="col-12 col-lg-auto mt-3 mt-lg-0">
            <ul className="list-inline list-inline-dots mb-0">
              © 2025 Pillars International
            </ul>
          </div>
        </div>
      </div>
  </footer>
  };
  
  export default Footer;