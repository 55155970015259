import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../components/modal";
import TextArea from "../../../components/textArea";
import ComponentSelect from './componentSelect';
import NumericInput from '../../../components/numericInput';
import SelectInput from '../../../components/selectInput';

import './settings.css';
import TextInput from '../../../components/textInput';


const VolumeSettings = ({ terms, updateTerms, periods }) => {
  const [activeItem, setActiveItem] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDelModal, setShowDelModal] = useState();

  useEffect(() => {
    if (activeItem) {
      var index = terms.findIndex((term) => term.uiId === activeItem.uiId);

      if (index !== -1) {
        terms[index] = activeItem;
      } else {
        terms.push(activeItem);
      }

      updateTerms(terms);
    }
  }, [activeItem])

  const handleChange = (name, value) => {
    if (name == "periodId") {
      setActiveItem((item) => ({ ...item, [name]: value, crossoverValues: null }));
    } else {
      setActiveItem((item) => ({ ...item, [name]: value }));
    }
  }

  const handleComponentChange = (componentId, parameters) => {
    setActiveItem((item) => ({ ...item, componentId: componentId, parameters: parameters }));
  }

  const handleCrossoverChange = (periodId, crossover) => {
    setActiveItem((prevItem) => {
      const crossoverValues = prevItem.crossoverValues || []; // Ensure crossoverValues exists

      // Find index of the existing item
      const existingIndex = crossoverValues.findIndex(item => item.periodId === periodId);

      let updatedCrossoverValues;
      if (existingIndex !== -1) {
        // Update existing item
        updatedCrossoverValues = crossoverValues.map((item, index) =>
          index === existingIndex ? { ...item, crossover: crossover } : item
        );
      } else {
        // Add new item
        updatedCrossoverValues = [...crossoverValues, { periodId: periodId, crossover: crossover }];
      }

      return {
        ...prevItem,
        crossoverValues: updatedCrossoverValues
      };
    });
  };


  const handleHide = () => setActiveItem();
  const handleShow = (uiId) => {
    var rank = terms.find((term) => term.uiId === uiId) ?? { uiId: crypto.randomUUID() };
    setActiveItem(rank);
  }

  const handleHideDelModal = () => setShowDelModal(false);
  const handleShowDelModel = (termId) => {
    setDeleteId(termId);
    setShowDelModal(true);
  }

  const deleteDefinition = () => {
    let index = terms.findIndex((el) => el.uiId == deleteId);
    if (index > -1) {
      terms.splice(index, 1)
      updateTerms(terms);
    }

    setActiveItem();
    setShowDelModal(false);
  }

  return <>
    <div className="card-header">
      <h2 className="mb-0">Glossary of Terms</h2>
    </div>
    <div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap datatable">
        <thead>
          <tr>
            <th>
              Index
            </th>
            <th>Code</th>
            <th>Term</th>
            <th>Description</th>
            <th>Period</th>
            <th>Component</th>
            <th className="w-1"></th>
          </tr>
        </thead>
        <tbody>
          {terms && terms.sort((a, b) => (a.index > b.index) ? 1 : -1).map((def) => {
            return <tr key={def.valueId}>
              <td>{def.index}</td>
              <td>{def.valueId}</td>
              <td>
                {def.name}
              </td>
              <td className="demo">
                <span className="me-1" data-bs-toggle="tooltip" data-bs-placement="top" title={def.comment}>{def.comment}</span>
              </td>
              <td>{def.periodId}</td>
              <td>{def.componentId}</td>
              <td>
                <div className="btn-list flex-nowrap">
                  <button className="btn" onClick={() => { handleShow(def.uiId) }}>
                    Edit
                  </button>
                  <button className="btn btn-icon" onClick={() => handleShowDelModel(def.uiId)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                  </button>
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    <div className="card-footer bg-transparent mt-auto">
      <div className="btn-list justify-content-end">
        <button className="btn btn-outline-primary" onClick={() => { handleShow() }} >
          New Term
        </button>
      </div>
    </div>

    <Modal showModal={activeItem ? true : false} size="xl" onHide={handleHide}>
      {activeItem && <>
        <div className="card-header">
          <h3 className="card-title">Term Details</h3>
          <div className="card-actions btn-actions">
            <button className="btn btn-action" onClick={handleHide}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-2">
              <label className="form-label">Index</label>
              <NumericInput name="index" value={activeItem.index} onChange={handleChange} />
            </div>
            <div className="col-6">
              <label className="form-label">Name</label>
              <TextInput name="name" value={activeItem.name} onChange={handleChange} />
            </div>
            <div className="col-2">
              <label className="form-label">Code</label>
              <TextInput name="valueId" value={activeItem.valueId} onChange={handleChange} />
            </div>
            <div className="col-2">
              <label className="form-label">Period</label>
              <SelectInput name="periodId" value={activeItem.periodId} onChange={handleChange}>
                {periods && periods.map((p) => {
                  return <option key={p.id}>{p.id}</option>
                })}
              </SelectInput>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Descriptions</label>
            <TextArea name="comment" value={activeItem.comment} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <ComponentSelect name="componentId" componentId={activeItem.componentId} parameters={activeItem.parameters} onChange={handleComponentChange} />
          </div>
        </div>
        <div className="card-body">
          <table className="table card-table">
            <thead>
              <tr>
                <th>Period</th>
                <th>Crossover Sync</th>
              </tr>
            </thead>
            <tbody>
              {periods && periods.map((period) => {

                const existing = activeItem.crossoverValues?.find(item => item.periodId === period.id);
                if (period.id != activeItem.periodId) {
                  return <tr key={period.id}>
                    <div className="col-8">{period.id}</div>
                    <td>
                      <SelectInput name={period.id} value={existing?.crossover ?? ''} onChange={handleCrossoverChange} >
                        <option value="DoNotSync">None</option>
                        <option value="Highest">Highest Value</option>
                        <option value="Lowest">Lowest Value</option>
                      </SelectInput>
                    </td>
                  </tr>
                }
              })}
            </tbody>
          </table>
        </div>
      </>}
    </Modal>

    <Modal showModal={showDelModal} size="sm" onHide={handleHideDelModal}>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div className="modal-body py-4">
        <div className="modal-title">Are you sure?</div>
        <div className="text-muted">Do you really want to remove this term? It cannot be undone.</div>
      </div>

      <div className="modal-footer">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <button type="button" className="btn w-100" data-bs-dismiss="modal">Cancel</button>
            </div>
            <div className="col">
              <button type="button" className="btn btn-danger w-100" onClick={deleteDefinition} >Delete Term</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
};

export default VolumeSettings;

VolumeSettings.propTypes = {
  terms: PropTypes.any.isRequired,
  updateTerms: PropTypes.func.isRequired,
  periods: PropTypes.any.isRequired
}
