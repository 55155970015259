import React from "react-dom/client";
import { useState } from 'react';
import { SendRequest } from '../../hooks/usePost';
import Footer from '../../pages/footer';
import TextInput from "../../components/textInput";
import CheckBox from "../../components/checkbox";

export default function Signup() {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setErrors(v => ({ ...v, [name]: null }));
    setFormData(v => ({ ...v, [name]: value }));
  }


  const validateField = (field, value, errors) => {
    if (!value) {
      errors[field] = `The ${field.charAt(0).toUpperCase() + field.slice(1)} field is required.`;
    }
  };
  
  const validateForm = (formData) => {
    const errors = {};
  
    validateField('firstName', formData?.firstName, errors);
    validateField('lastName', formData?.lastName, errors);
    validateField('emailAddress', formData?.emailAddress, errors);
    validateField('password', formData?.password, errors);
    validateField('cpassword', formData?.cpassword, errors);
  
    if (formData.password !== formData.cpassword) {
      errors.cpassword = "The Confirm Password and confirmation password do not match.";
    }
  
    if (!formData.acceptsTerms) {
      errors.acceptsTerms = "The Accept Terms field must be checked in order to continue.";
    }
  
    return errors;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formErrors = validateForm(formData);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setLoading(true);
      SendRequest('POST', `/api/v1/Environments/CreateSubscription`, formData, () => {
        setLoading(false);
        window.location = "/";
      }, (error) => {
        setLoading(false);
        setErrors(v => ({ ...v, general: error }));
      })
    }
  }

  return <>
    <div className="page-wrapper page-center">
      <div className="container container-narrow py-4" style={{ maxWidth: "35rem" }}>
        <div className="card card-md">
          <div className="card-body">
            <div className="text-center mb-4 d-print-none text-white">
              <a href="." className="navbar-brand navbar-brand-autodark">
                <img src="/images/logo-dark.png" height="46" alt="Pillars" />
              </a>
            </div>
            <h2 className="h2 text-center mb-4">Create new account</h2>
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <div className="mb-3">
                <label className="form-label">First Name</label>
                <TextInput errorText={errors.firstName} name="firstName" value={formData.firstName} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label className="form-label">Last Name</label>
                <TextInput errorText={errors.lastName} name="lastName" value={formData.lastName} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label className="form-label">Email address</label>
                <TextInput type="email" errorText={errors.emailAddress} name="emailAddress" value={formData.emailAddress} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Password
                </label>
                <TextInput type="password" errorText={errors.password} name="password" value={formData.password} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Confirm Password
                </label>
                <TextInput type="password" errorText={errors.cpassword} name="cpassword" value={formData.cpassword} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <CheckBox errorText={errors.acceptsTerms} name="acceptsTerms" value={formData.acceptsTerms} onChange={handleChange} >
                  <span className="form-check-label">
                    I accept the <a href="https://docs.commissionsportal.com/en/terms-of-service" target="_blank" tabIndex="-1" rel="noreferrer">Terms of Service</a> and have read the <a href="https://docs.commissionsportal.com/en/privacy-statement" target="_blank" tabIndex="-1" rel="noreferrer">Privacy Notice</a>
                  </span>
                </CheckBox>
                <span className="text-danger field-validation-valid" data-valmsg-for="Input.AcceptTerms" data-valmsg-replace="true">{errors.general}</span>
              </div>

              <span id="loginError" className="text-danger"></span>
              <div className="form-footer">
                {loading && <button className="btn btn-primary w-100"><span className="spinner-border spinner-border-sm me-2" role="status"></span>Creating Account</button>}
                {!loading && <button type="submit" className="btn btn-primary w-100">Create Account</button>}
              </div>
            </form>
            <div className="text-center text-muted mt-3">
              Return to <a href="/">Sign In</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
}